<template>
	<div>
		<div class="pd20" style="height: calc(100vh - 60px);">
			<a-spin :spinning="loading">
				<div class="pd20 bg-w" style="height: 100%;">
					<div class="flex center ft18 cl-main">订单详情</div>
					<div class="mt20">
						<div class="flex alcenter space">
							<div class="flex alcenter">
								<div class="">
									<div class="flex alcenter">
										<div class="flex alcenter">
											<div class="ft14 ftw400 cl-info">订单编号：</div>
											<div class="ft14 ftw600 cl-main">{{order.order_id}}</div>
										</div>

										<div class="flex alcenter ml40">
											<div class="ft14 ftw400 cl-info">下单时间：</div>
											<div class="ft14 ftw600 cl-main">{{order.add_time_format}}</div>
										</div>
									</div>
								</div>
							</div>
							<div>
								<a-button type="default" @click="$router.go(-1)">返回</a-button>
							</div>
						</div>

						<div class="mt30">
							<div class="cash-order-detail-info">
								<div class="flex ">
									<div class="pl40" style="width: 33%;border-right: 1px solid #EBEDF5;">
										<div class="ft14 ftw500 cl-main">订单状态</div>
										<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==1">待付款</div>
										<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==2">已支付</div>
										<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==-1">已取消</div>
										<div class="big-button-pay mt16" @click="cancelOrderAct()" v-if=" order.status==0">取消订单
										</div>
									</div>
									<div class="" style="width: 33%;">
										<div class="flex" style="border-right: 1px solid #EBEDF5; ">
											<div class="ml40 text-over4">
												<div class="ft14 ftw500 cl-main text-over4">付款信息</div>
												<div class="mt16">
													<div class="flex">
														<div>
															<div class="flex alcenter">
																<div class="ft14 ftw400 cl-info text-over4">实付金额：</div>
																<div class="ft14 ftw600 cl-main text-over4">
																	¥{{order.need_pay}}</div>
															</div>
															<div class="flex alcenter mt10">
																<div class="ft14 ftw400 cl-info text-over4">支付方式：</div>
																<div class="ft14 ftw600 cl-main text-over4">
																	{{order.pay_type_mean}}
																</div>
															</div>
															<div class="flex alcenter mt10">
																<div class="ft14 ftw400 cl-info text-over4">支付时间：</div>
																<div class="ft14 ftw600 cl-main text-over4">
																	{{order.pay_time_format}}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="" style="width: 33%;">
										<div class="ml40">
											<div class="ft14 ftw500 cl-main">客户信息</div>
											<div class="mt16">
												<div class="flex" v-if="order.member">
													<div>
														<div class="flex alcenter">
															<div class="ft14 ftw400 cl-info text-over4">客户姓名：</div>
															<div class="ft14 ftw600 cl-main text-over4">
																{{order.member_name}}
															</div>
														</div>
														<div class="flex alcenter mt10">
															<div class="ft14 ftw400 cl-info text-over4">手机号码：</div>
															<div class="ft14 ftw600 cl-main text-over4">
																{{order.member_mobile}}
															</div>
														</div>
													</div>
												</div>
												<div class="flex" v-else>
													<div class="ft16 ftw600 cl-main text-over4">
														游客
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="mt30">
							<div class="flex wrap">
								<div class="cash-order-detail-subcard-item" v-for="(item,index) in order.items">
									<div class="flex  alcenter space">
										<div class="flex alcenter">
											<img v-if="item.goods_cover_img != null" :src="item.goods_cover_img" />
											<div class="ml10">
												<div class="ft14 ftw500 cl-info" style="width: 60%;">

													{{item.goods_name}}
												</div>
												<div class="ft12 ftw400 cl-notice">{{item.goods_sku_name}}</div>
											</div>
											<div style="margin-left: 200px;" class="ft14 ftw500 cl-info flex alcenter">
												<div>¥{{item.goods_price}}</div>
												<div class="ml8">x{{item.goods_num}}</div>
											</div>
										</div>
										<div></div>
									</div>
								</div>
							</div>
						</div>

						<div class="mt30 order-detail-box" v-if="datas.length > 0">
							<div class="flex wrap ticheng" >
								<div class="title">业绩分成</div>
								<a-table :columns="columns" :data-source="datas" rowKey="id" :scroll="{x:'calc(100%)',y: 500}"
									:pagination="false" :loading="false">
									<div slot="technician_id" slot-scope="technician_id,record">
										{{record.name?record.name:'-'}}(ID:{{record.technician_id?record.technician_id:'0'}})
									</div>
									<div slot="goods_type" slot-scope="goods_type,record">
										<span v-if="record.goods_type == 'goods'">产品</span>
										<span v-if="record.goods_type == 'xiangmu'">项目</span>
										<span v-if="record.goods_type == 'chuzhika'">储值卡</span>
										<span v-if="record.goods_type == 'taocan'">套餐</span>
									</div>
									<div slot="partner_rate" slot-scope="partner_rate,record">
										{{record.partner_rate?record.partner_rate+'%':'0'}}
									</div>
									<div slot="award_type" slot-scope="award_type,record">
										<span v-if="record.award_type == 1">固定(元)</span>
										<span v-if="record.award_type == 2">百分比(%)</span>
									</div>
									
									</a-table>
							</div>
						</div>
						<div class="mt30 order-detail-box" v-if="datasShougong.length > 0">
							<div class="flex wrap ticheng" >
								<div class="title">手工费分成</div>
								<a-table :columns="columns" :data-source="datasShougong" rowKey="id" :scroll="{x:'calc(100%)',y: 500}"
									:pagination="false" :loading="false">
									<div slot="technician_id" slot-scope="technician_id,record">
										{{record.name?record.name:'-'}}(ID:{{record.technician_id?record.technician_id:'0'}})
									</div>
									<div slot="goods_type" slot-scope="goods_type,record">
										<span v-if="record.goods_type == 'goods'">产品</span>
										<span v-if="record.goods_type == 'xiangmu'">项目</span>
										<span v-if="record.goods_type == 'chuzhika'">储值卡</span>
										<span v-if="record.goods_type == 'taocan'">套餐</span>
									</div>
									<div slot="partner_rate" slot-scope="partner_rate,record">
										{{record.partner_rate?record.partner_rate+'%':'0'}}
									</div>
									<div slot="award_type" slot-scope="award_type,record">
										<span v-if="record.award_type == 1">固定(元)</span>
										<span v-if="record.award_type == 2">百分比(%)</span>
									</div>
									
									</a-table>
							</div>
						</div>
						<div class="flex end mt10">
							<div class="flex">
								<div class="ft12 ftw400 cl-info">
									<div class="flex end">合计</div>

									<div class="flex end mt8" v-if="order.coupon_money>0">优惠券抵扣</div>

									<div class="flex end mt8" v-if="order.youhui_balance > 0">优惠金额</div>
									<div class="flex end mt8 ft14 ftw500 cl-black">订单金额</div>
								</div>

								<div class="ml60 ft12 ftw400 cl-info">
									<div class="flex end">¥{{order.total_price}}</div>
									<div class="flex end mt8" v-if="order.coupon_money>0">¥{{order.coupon_money}}</div>

									<div class="flex end mt8" v-if="order.youhui_balance > 0">¥{{order.youhui_balance}}
									</div>
									<div class="flex end mt8 ft14 ftw500 cl-black">¥{{order.need_pay}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a-spin>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				order_id: 0,
				order: {
					order_id: 0,
					add_time_format: '',
					order_type_means: '',
					status: 0,
					status_means: '',
					need_pay: 0,
					pay_type_mean: '',
					pay_time_format: '',
					total_price: 0,
					coupon_money: 0,
					member: {},
					member_name: '',
					member_mobile: '',
					items: [],
					
				},
				datas:[],
				datasShougong:[],
				columns:[
					{title: 'ID',dataIndex: 'id',align: 'center'},
					{title: '提成人',dataIndex: 'technician_id',align: 'center',scopedSlots: {customRender: 'technician_id'}},
					{title: '分成类型',dataIndex: 'goods_type',align: 'center',scopedSlots: {customRender: 'goods_type'}},
					{title: '价格',dataIndex: 'price',align: 'center'},
					{title: '数量',dataIndex: 'num',align: 'center'},
					{title: '分摊比例',dataIndex: 'partner_rate',align: 'center'},
					{title: '分摊金额',dataIndex: 'partner_amount',align: 'center'},
					{title: '提成值',dataIndex: 'val',align: 'center'},
					{title: '提成类型',dataIndex: 'award_type',align: 'center',scopedSlots: {customRender: 'award_type'}},
					{title: '提成金额',dataIndex: 'ticheng_amount',align: 'center'},
					{title: '时间',dataIndex: 'add_time_format',align: 'center',width:200},
					
				]
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.order_id = 0;
			} else {
				this.order_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		methods: {
			sfgdetail(){
				this.$http.api('sfgdetail', {
					order_id: this.order_id,
					page:1,
					limit:500
				}).then(res => {
					this.datasShougong = res.list;
					console.log('res',res)
				}).catch(res => {
					
				})
			},
			yejidetail(){
				this.$http.api('yejidetail', {
					order_id: this.order_id,
					page:1,
					limit:500
				}).then(res => {
					this.datas = res.list;
					console.log('res',res)
				}).catch(res => {
					
				})
			},
			loaddata() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('getOrderDetail', {
					order_id: this.order_id
				}).then(res => {
					this.order = res.detail;
					this.loading = false;
					this.yejidetail();
					this.sfgdetail();
				}).catch(res => {
					console.log(res);
					this.loading = false;
					this.$router.go(-1);
				})
			},
			cancelOrderAct(){
				this.$confirm({
					title:'确定取消这个订单吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('cancelOrder',{
								order_id:this.order_id,
							}).then(res=>{
								this.$message.success('取消成功',1,()=>{
									this.getLists();
								});
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
		}
	}
</script>

<style>
	.order-detail-box .ticheng{
		padding: 10px;
		 border: 1px solid #eeeeee;
	}
	.order-detail-box .ticheng .title{
		width: 100%;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 15px;
	}
	.cash-order-detail-tag {
		padding: 1px 8px;
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 16px;
	}

	.cash-order-detail.tag {
		padding: 1px 4px;
		background: rgba(71, 114, 255, 0.1);
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
	}


	.cash-order-detail-info {
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		padding: 24px 0px;
	}

	.cash-order-detail-subcard-item {
		width: 100%;
		height: 100px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		margin-bottom: 20px;
		padding: 20px;
	}

	.cash-order-detail-subcard-item img {
		width: 75px;
		height: 60px;
	}
</style>
